.SankeyGraph {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.Footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #555555;
    text-align: center;
    text-justify: inter-word;
    width: 50%;
    border-radius: 2vh;
    padding-right: 1vh;
    padding-left: 1vh;
}
.Intro {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #555555;
    text-align: justify;
    text-justify: inter-word;
    width: 95%;
    border-radius: 2vh;
    padding-right: 1vh;
    padding-left: 1vh;
}
.reportLink{
    flex:1;
    width: 100%;
    text-decoration:underline;
    color: rgb(250, 113, 89);
}
.IntroP {
    width: 100%;
    font-size: 2vh;
    color: #f3f3eb;
}

@media only screen and (min-width: 800px) {
    #param1 {
        position: absolute;
        left: 28%;
    }
    #param2 {
        position: relative;
        left: 46%;
    }
    #param3 {
        position: absolute;
        right: 24%;
    }
}

@media only screen and (max-width: 800px) {
    #param1 {
        flex: 1;
    }
    #param2 {
        flex: 1;
    }
    #param3 {
        flex: 1;
    }
}

.ParamSelect {
    flex: 1;
    max-width: 75%;
    border-radius: 2vh;
}

.Graph {
    flex: 1;
    max-width: 800;
    justify-content: center;
    padding-bottom: 2vh;
}

.GraphHeader {
    display: flex;
    width: 100%;
    background-color: #333333;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
}

.GraphTitle {
    font-size: 2vh;
    color: #f3f3eb;
}

.GraphControlls {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10%;
    flex-wrap: wrap;
    width: 90%;
}

.sankeyLink:hover {
    stroke-opacity: .5;
}

.nodeRectText {
    font-weight: bolder;
}

.scatterCircle{
    fill-opacity: "0.6";
    mix-blend-mode: darken;
}

.sankeyLinkTitle {
    text-align: center;
    border-radius: 5vh;
}
#param1Color {
    width: 2.5vh;
    height: 2.5vh;
    background-color: #fb0c1d;
    border-radius: 50%;
}

#param2Color {
    height: 2.5vh;
    width: 2.5vh;
    border-radius: 50%;
    background-color: #0d0da3;
}

.paramColorContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}